import { AuthUser } from '@/types/types';
import { useOrganization, useOrganizationList, useUser } from '@clerk/nextjs';
import { useCallback } from 'react';
// import { useAuth as useClerkAuth } from '@clerk/nextjs';
export function useAuth() {
    const { isSignedIn, user } = useUser();
    const { organization, membership } = useOrganization();
    const { isLoaded, setActive } = useOrganizationList();

    return {
        isSignedIn,
        user: {
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            fullName: user?.fullName,
            username: user?.username,
            organizationId: organization?.id,
            organizationName: organization?.name,
            orgAccessRole: membership?.role,
        } as AuthUser,
        setActiveOrganization: useCallback(
            (organizationId: string) => {
                console.log('setActiveOrganization', organizationId, isLoaded);
                if (isLoaded && organizationId) setActive({ organization: organizationId });
            },
            [setActive, isLoaded],
        ),
    };
}
