import { TextInput as TextFieldInput } from '@/components/atoms/text-input';
import { InputWrapper } from '../wrapper/input-wrapper';
import { Field, FieldProps } from 'formik';
import { Flex, FlexItem } from '@/components/atoms/flex';
import { getErrorMessage } from '@/libs/utils/helpers';

type TimeFieldProps = FieldProps & {
    label?: string;
    name: string;
    placeholder: string;
    required: boolean;
    value: string;
};

export const TimeField: React.FC<TimeFieldProps> = ({ label, field, form, ...props }) => {
    const error = getErrorMessage(field, form);
    return (
        <InputWrapper htmlFor={props.name} value={label}>
            <TextFieldInput
                id={props.name}
                type='time'
                color={error ? 'failure' : undefined}
                {...field}
                {...props}
            />
        </InputWrapper>
    );
};

type RangeInputProps = {
    label?: string;
    required?: boolean;
    startTimeField?: { name: string };
    endTimeField?: { name: string };
};

export const TimeRangeInput: React.FC<RangeInputProps> = ({
    label,
    startTimeField = { name: 'start' },
    endTimeField = { name: 'end' },
    ...props
}) => {
    return (
        <InputWrapper htmlFor={startTimeField.name} value={label}>
            <Flex fullWidth direction='row' gapX={2}>
                <FlexItem className='w-1/2'>
                    <Field component={TimeField} {...props} {...startTimeField} />
                </FlexItem>
                <FlexItem className='w-1/2'>
                    <Field component={TimeField} {...props} {...endTimeField} />
                </FlexItem>
            </Flex>
        </InputWrapper>
    );
};
