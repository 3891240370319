import { dashboardState } from '../states/dashboard-state';

type DashboardAction = {
    setSidebarCollapse: (val: boolean) => void;
    setAppointmentQueryDate: (date: Date) => void;
    setShowConfirmModal: (val: boolean) => void;
    setConfirmModalData: (data: {
        title?: string;
        message?: string;
        highlight?: any;
        onConfirm?: () => void;
    }) => void;
    setConfirmModalProcessing: (val: boolean) => void;
    setShowOnBoardingModal: (val: boolean) => void;
};

export const dashboardAction: DashboardAction = {
    setSidebarCollapse: (val) => (dashboardState.sidebar.collapsed = val),
    setAppointmentQueryDate: (date) => (dashboardState.appointment.queryDate = date),
    setShowConfirmModal: (val) => (dashboardState.confirmModal.show = val),
    setConfirmModalData: (data) => (dashboardState.confirmModal.data = data),
    setConfirmModalProcessing: (val) => (dashboardState.confirmModal.data.isProcessing = val),
    setShowOnBoardingModal: (val) => (dashboardState.onBoardingModal.show = val),
};
