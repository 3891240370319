'use client';
import { Flex } from '@/components/atoms/flex';
import { Modal } from '@/components/atoms/modal';

import { Display } from '@/components/atoms/typography';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import OnboardingForm from '../form';
import ServiceProviderTypeSwitch from '@/components/molecules/switch-provider';
import useOnBoardingModal from '@/libs/hooks/useOnBoardingModal';
import { useAuth } from '@/libs/hooks/use-auth';
import isUndefined from 'lodash/isUndefined';

const OnboardingModal = () => {
    const {
        formikRef,
        onBoarded,
        fields,
        initialValues,
        show,
        spType,
        heading,
        isOrgMember,
        setIsOpen,
        handleSwitchSPType,
        handleSubmit,
        handleBackStep,
    } = useOnBoardingModal();
    const { user } = useAuth();

    return (
        <Modal size='xl' show={show} onClose={onBoarded ? () => setIsOpen(false) : undefined}>
            <Modal.Body>
                {onBoarded && <Modal.Header className='border-0 p-0' />}
                {spType.step > 1 && (
                    <span className='group/icon absolute left-6 top-6 cursor-pointer rounded-lg p-1.5 hover:bg-gray-200'>
                        <ArrowLeftIcon
                            className='size-5 cursor-pointer text-gray-400 group-hover/icon:text-gray-900'
                            onClick={handleBackStep}
                        />
                    </span>
                )}
                <Flex direction='column' gapY={4} className='m-auto max-w-sm'>
                    {spType.step === 1 && (!isOrgMember || isUndefined(user.organizationId)) ? (
                        <ServiceProviderTypeSwitch onActiveTabChange={handleSwitchSPType} />
                    ) : null}
                    <Display as='h3' weight='bold' lineHeight='10'>
                        {heading}
                    </Display>
                    <OnboardingForm
                        formRef={formikRef}
                        initialValues={initialValues}
                        fields={fields}
                        formType={spType.type}
                        step={spType.step}
                        handleSubmit={handleSubmit}
                    />
                </Flex>
            </Modal.Body>
        </Modal>
    );
};

export default OnboardingModal;
