import {
    ToastOptions,
    ToastType,
    DefaultToastOptions,
    toast,
    ValueOrFunction,
    Renderable,
    Toast,
} from 'react-hot-toast';
import { getKey } from '../utils/helpers';

export const defaultToastOptions: DefaultToastOptions = {
    id: getKey(),
    position: 'bottom-right',
};

type Message = ValueOrFunction<Renderable, Toast>;

/**
 * Display toast
 *
 * @param {ToastType} type
 * @param {Message} message
 * @param {ToastOptions} [options=defaultToastOption]
 * @return {string}
 */
export const showToast = (
    type: ToastType,
    message: Message,
    options: Partial<ToastOptions> = {},
): string => {
    const optionsToApply = { ...defaultToastOptions, ...options };

    switch (type) {
        case 'success':
            return toast.success(message, optionsToApply);
        case 'error':
            return toast.error(message, optionsToApply);
        default:
            return toast(message, optionsToApply);
    }
};
