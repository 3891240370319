import {
    type CustomFlowbiteTheme,
    Button as FButton,
    type ButtonProps,
    Spinner,
} from 'flowbite-react';
import { forwardRef } from 'react';

// Custom button theme
export const buttonTheme: CustomFlowbiteTheme['button'] = {
    base: 'group relative flex items-stretch justify-center text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none',
    color: {
        neutral:
            'border border-neutral-300 bg-white text-neutral-900 enabled:hover:bg-neutral-100 focus:ring-2 focus:ring-neutral-300 dark:border-neutral-600 dark:bg-neutral-700 dark:text-white dark:hover:bg-neutral-600',
        primary:
            'border border-primary-700 bg-primary-700 text-white focus:ring-2 focus:ring-primary-300 enabled:hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800',
        danger: 'border border-danger-700 bg-danger-700 text-white focus:ring-2 focus:ring-danger-300 enabled:hover:bg-danger-800 dark:bg-danger-600 dark:focus:ring-danger-900 dark:enabled:hover:bg-danger-700',
        ghost: 'bg-transparent text-neutral-600 enabled:hover:bg-neutral-100 focus:ring-2 focus:ring-neutral-300 dark:border-neutral-600 dark:bg-neutral-700 dark:text-white dark:hover:bg-neutral-600',
        accept: 'bg-white text-success-600 focus:ring-2 focus:ring-success-300 enabled:hover:bg-success-100',
        reject: 'bg-white text-red-700 focus:ring-2 focus:ring-danger-300 enabled:hover:bg-danger-100',
    },
    inner: {
        base: 'flex items-stretch transition-all duration-200',
    },
    outline: {
        color: {
            neutral: 'text-neutral-900 ',
            primary: 'bg-white text-primary-700 enabled:hover:text-white',
            danger: 'bg-white text-danger-700 enabled:hover:text-white',
        },
        off: '',
        on: 'flex w-full justify-center bg-white transition-background duration-75 ease-in group-enabled:group-hover:bg-opacity-0 enabled:group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white',
        pill: {
            off: 'rounded-[inherit]',
            on: 'rounded-full',
        },
    },
};

// Main button component
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <FButton
            {...props}
            ref={ref}
            theme={buttonTheme}
            processingSpinner={<Spinner size={props.size} color={props.color} />}
        />
    );
});

Button.displayName = 'Button';

export default Button;

// Button variants
export const OutlineButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button color='neutral' outline {...props} ref={ref} />;
});

export const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button {...props} ref={ref} color='primary' />;
});

PrimaryButton.displayName = 'PrimaryButton';

export const NeutralButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button {...props} ref={ref} color='neutral' />;
});

NeutralButton.displayName = 'NeutralButton';

export const DangerButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button {...props} color='danger' ref={ref} />;
});
DangerButton.displayName = 'DangerButton';

export const AcceptButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button {...props} color='accept' ref={ref} />;
});

AcceptButton.displayName = 'AcceptButton';

export const RejectButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button {...props} color='reject' ref={ref} />;
});

RejectButton.displayName = 'RejectButton';
