import { Flex } from '@/components/atoms/flex';
import { Label } from '@/components/atoms/label';
import { PropsWithChildren } from 'react';

type InputWrapperProps = PropsWithChildren & {
    htmlFor?: string;
    value?: string;
    error?: string;
};
export const InputWrapper: React.FC<InputWrapperProps> = ({ children, error, ...rest }) => {
    const color = error ? 'failure' : 'neutral-700';
    return (
        <Flex direction='column' gapY={1}>
            <Label {...rest} htmlFor={rest.htmlFor} hidden={!rest.value} color={color} />
            {children}
        </Flex>
    );
};
