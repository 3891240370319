import { type TabsProps as FTabsProps } from 'flowbite-react';
import { twJoin } from 'tailwind-merge';
import { tv, type VariantProps } from 'tailwind-variants';

const tabsVariants = tv({
    // base: 'gap-2',
    variants: {
        vertical: {
            true: twJoin(
                'vertical-tabs flex flex-row flex-nowrap gap-x-8 rounded-lg border-none child:shadow-none',
                ' [&>div:first-child]:w-1/4 [&>div:first-child]:flex-col [&>div:last-child]:w-full',
                ' [&>div>button]:rounded-lg [&>div>button]:py-3 [&>div:first-child]:sticky [&>div:first-child]:top-0',
                ' [&>div:last-child]:max-h-32.25 [&>div:last-child]:overflow-y-auto [&>div:first-child]:space-x-0 [&>div:first-child]:gap-2',
            ),
        },
    },
});

type TabsProps = VariantProps<typeof tabsVariants> & FTabsProps;

export { tabsVariants, type TabsProps };
