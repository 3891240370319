import { useState, useRef } from 'react';
import {
    useFetchUserConfigurationQuery,
    useUpdateUserConfigurationMutation,
} from '../api/userConfiguration';
import { FormikHelpers } from 'formik';

import {
    useCreateAvailabilityConfiguration,
    useFetchAvailability,
} from '../api/availabilityConfiguration';
import { Fields, FormFieldType } from '../form-helpers/fields';
import { OrgAccessRole, ServiceProviderType } from '../enums';
import { serviceProviderTypes } from '@/components/molecules/switch-provider';
import { mutate } from 'swr';
import { getDefaultTimeZone, getTodayDateString } from '../utils/datetime-helpers';
import { useAuth } from './use-auth';
import { getDefaultCurrencyValue, queryBuilder } from '../utils/helpers';
import { useSnapshot } from 'valtio';
import { dashboardState } from '../valtio/states/dashboard-state';
import { dashboardAction } from '../valtio/actions/dashboard-action';

const getFormattedAvailabilityAPIInputData = (values: any, organizationId?: string) => {
    return {
        organizationId: organizationId,
        organizationName: values.organizationName,
        organizationType: values.organizationtype,
        currency: values.currency,
        availabilityConfiguration: {
            timezone: values.timezone,
            startTime: values.startTime,
            endTime: values.endTime,
            duration: values.duration,
            days: values.days,
        },
    };
};
const useOnBoardingModal = () => {
    const { user, setActiveOrganization } = useAuth();
    const formikRef = useRef<FormikHelpers<any>>(null);

    const { show } = useSnapshot(dashboardState.onBoardingModal);
    const [spType, setSPType] = useState({
        type: ServiceProviderType.ORGANIZATION,
        step: 1,
    });

    const { data, isLoading } = useFetchUserConfigurationQuery({
        onSuccess: ({ userConfiguration }) => {
            if (!userConfiguration?.onBoardingModal) {
                dashboardAction.setShowOnBoardingModal(true);
            }
        },
        onError: (err) => {
            console.error(err);
        },
    });

    const { data: availabilityData, isLoading: loadingAvailabilities } = useFetchAvailability(
        user.organizationId,
    );

    const onBoarded = data?.userConfiguration?.onBoardingModal === true || false;
    const isOrgMember =
        user.organizationId && user.orgAccessRole === OrgAccessRole.MEMBER ? true : false;
    const hasAvailabilities =
        loadingAvailabilities === false &&
        availabilityData &&
        availabilityData?.availabilities.length === 0
            ? false
            : true;

    function getInitialValues() {
        if (user.organizationId) {
            return {
                organizationName: user.organizationName || '',
                organizationtype: '',
                timezone: getDefaultTimeZone(),
                currency: getDefaultCurrencyValue(),
                startTime: '09:00',
                endTime: '17:00',
                duration: 30,
                days: [],
            };
        } else {
            return {
                organizationName: '',
                organizationtype: '',
                timezone: getDefaultTimeZone(),
                currency: getDefaultCurrencyValue(),
                startTime: '09:00',
                endTime: '17:00',
                duration: 30,
                days: [],
            };
        }
    }

    const getOnboardingFormFields = () => {
        const stepOneFields: FormFieldType[] =
            spType.type === ServiceProviderType.ORGANIZATION
                ? [{ ...Fields.ORGANIZATION_NAME, disabled: user.organizationName ? true : false }]
                : [];
        stepOneFields.push(
            { ...Fields.TIMEZONE },
            { ...Fields.CURRENCY, disabled: isOrgMember ? true : false },
        );

        const stepTwoFormFields = [Fields.WORKING_HOURS, Fields.SLOT_DURATION, Fields.WORKING_DAYS];

        const formStepFields = {
            1: stepOneFields,
            2: stepTwoFormFields,
        };

        return formStepFields[spType.step as 1 | 2] ?? [];
    };

    const getHeading = () => {
        if (spType.step == 1) {
            if (spType.type === ServiceProviderType.ORGANIZATION) {
                if (user.organizationId && (!onBoarded || !hasAvailabilities)) {
                    if (isOrgMember) {
                        return 'Member details';
                    } else {
                        return 'Admin details';
                    }
                } else {
                    return 'Organization details';
                }
            } else if (spType.type === ServiceProviderType.INDIVIDUAL) {
                return 'Register yourself';
            }
        } else if (spType.step === 2) {
            return 'Your availability';
        }
    };

    const handleSwitchSPType = (tab: number) => {
        const updateSpType = serviceProviderTypes[tab].value;
        setSPType({ ...spType, type: updateSpType });
        if (spType.step === 2) {
            setSPType({ ...spType, step: 1, type: updateSpType });
        }
        if (formikRef.current) {
            formikRef.current?.resetForm();
        }
    };

    const handleSwitchSPStep = () => {
        setSPType({ ...spType, step: 2 });
    };

    const handleBackStep = () => {
        if (spType.step > 1) {
            setSPType({ ...spType, step: spType.step - 1 }); // Decrement the step when going back
        }
    };

    const { trigger: postData } = useUpdateUserConfigurationMutation({
        onSuccess: () => {
            const params = queryBuilder({ organizationId: user.organizationId });
            const url = user.organizationId
                ? '/api/availability'.concat(`?${params}`)
                : '/api/availability';
            mutate(url);
            const todayDate = getTodayDateString();
            mutate(`/api/booking_service/appointment?date=${todayDate}`);
            mutate('/api/booking_service');
        },
    });
    const handleUpdateOnboardingStatus = () => {
        postData({ onBoardingModal: true });
    };

    const { trigger: createAvailabilityConfiguration } = useCreateAvailabilityConfiguration({
        onSuccess: (data) => {
            if (data) {
                if (data.organizationId) {
                    setActiveOrganization(data.organizationId);
                }
                dashboardAction.setShowOnBoardingModal(false);
                handleUpdateOnboardingStatus();
            }
        },
    });

    const handleSubmit = async (
        values: ReturnType<typeof getInitialValues>,
        formikHelpers: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
        const data = getFormattedAvailabilityAPIInputData(values, user.organizationId);
        try {
            if (spType.step === 1) {
                handleSwitchSPStep();
            } else if (spType.step === 2) {
                await createAvailabilityConfiguration(data);
            }
        } catch (err: any) {
            if (err?.info?.error) {
                formikHelpers.setStatus({ submitError: err.info.error });
            }
        }
        formikHelpers.setSubmitting(false);
    };

    return {
        isLoading,
        formikRef,
        data,
        onBoarded,
        hasAvailabilities,
        show,
        spType,
        isOrgMember,
        heading: getHeading(),
        fields: getOnboardingFormFields(),
        initialValues: getInitialValues(),
        handleSwitchSPType,
        handleSwitchSPStep,
        handleBackStep,
        setIsOpen: dashboardAction.setShowOnBoardingModal,
        handleSubmit,
    };
};

export default useOnBoardingModal;
