import React from 'react';
import { TextProps, textVariants } from './text.variants';

export const Text = React.forwardRef<HTMLElement, TextProps>(
    (
        { as = 'p', size, className, weight, color, align, truncate, lineHeight, inline, ...rest },
        ref,
    ) => {
        return React.createElement(as, {
            ...rest,
            className: textVariants({
                size,
                weight,
                color,
                align,
                truncate,
                lineHeight,
                inline,
                className,
            }),
            ref,
        });
    },
);

export { type TextProps, textVariants };
