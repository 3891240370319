import { usePathname, useSearchParams } from 'next/navigation';
import { useSnapshot } from 'valtio';
import { dashboardState } from '../valtio/states/dashboard-state';
import { dashboardAction } from '../valtio/actions/dashboard-action';
import { UsersIcon, FolderIcon, CogIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { OrgAccessRole, SettingsTab } from '@/libs/enums';
// import { useOrganization } from '@clerk/nextjs';
import { SidebarItemOption } from '@/types/types';
import { useAuth } from './use-auth';
import { useEffect } from 'react';
import { isSmallScreen } from '../utils/helpers';

const useSidebar = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const tab = searchParams?.get('tab');

    const snap = useSnapshot(dashboardState);
    const { user } = useAuth();
    const isCollapsed = snap.sidebar.collapsed;
    function toggleSidebar() {
        dashboardAction.setSidebarCollapse(!isCollapsed);
    }

    // Close Sidebar on mobile tap inside main content
    useEffect(() => {
        function handleMobileTapInsideMain(event: MouseEvent) {
            const main = document.querySelector('#dashboard-template');
            const isClickInsideMain = main?.contains(event.target as Node);
            if (isSmallScreen() && isClickInsideMain) {
                dashboardAction.setSidebarCollapse(true);
            }
        }
        document.addEventListener('mousedown', handleMobileTapInsideMain);
        return () => {
            document.removeEventListener('mousedown', handleMobileTapInsideMain);
        };
    }, []);

    function getItemOptions(): SidebarItemOption[] {
        const options = [];
        switch (pathname) {
            case '/settings':
                options.push(
                    {
                        text: 'Configuration',
                        key: SettingsTab.CONFIGURATION,
                        icon: CogIcon,
                        href: `/settings?tab=${SettingsTab.CONFIGURATION}`,
                        isActive: tab === SettingsTab.CONFIGURATION,
                    },
                    // {
                    //     text: 'Payout',
                    //     key: 'payout',
                    //     icon: CreditCardIcon,
                    //     href: `/settings?tab=${SettingsTab.PAYOUT}`,
                    //     isActive: tab === 'payout',
                    // },
                );

                if (user.organizationId !== undefined) {
                    options.push({
                        text: 'Members',
                        key: 'members',
                        icon: UsersIcon,
                        href: `/settings?tab=${SettingsTab.MEMBERS}`,
                        isActive: tab === 'members',
                    });
                }
                if (user.organizationId && user?.orgAccessRole === OrgAccessRole.ADMIN) {
                    options.unshift({
                        text: 'Overview',
                        key: 'overview',
                        icon: FolderIcon,
                        href: `/settings?tab=${SettingsTab.OVERVIEW}`,
                        isActive: tab === 'overview',
                    });
                }
                break;

            default:
                options.push(
                    {
                        text: 'Appointments',
                        key: 'appointments',
                        icon: CalendarDaysIcon,
                        href: '/dashboard',
                        isActive: pathname === '/dashboard',
                    },
                    {
                        text: 'Calendar',
                        key: 'calendar',
                        icon: CalendarDaysIcon,
                        href: '/calendar',
                        isActive: pathname === '/calendar',
                    },
                );
                break;
        }
        return options;
    }

    return {
        itemOptions: getItemOptions(),
        isCollapsed,
        toggleSidebar,
    };
};

export default useSidebar;
