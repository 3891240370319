'use client';
import Container from '@/components/atoms/container';
import DashboardNavbar from '@/components/organisms/dashboard/dashboard-navbar';
import DashboardSidebar from '@/components/organisms/dashboard/dashboard-sidebar';
import OnboardingModal from '@/components/organisms/onboarding/modal';
import useSidebar from '@/libs/hooks/use-sidebar';
import { Fragment, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';
import { Flex } from '@/components/atoms/flex';
import Image from 'next/image';
import { BodyXSmall, Caption } from '@/components/atoms/typography';
import ConfirmModal from '@/components/molecules/confirm-modal';
import useOnBoardingModal from '@/libs/hooks/useOnBoardingModal';
import { CenterSpinner } from '@/components/atoms/spinner';
import useSocketConnectionHook from '@/libs/hooks/use-socket-event';
import { showToast } from '@/libs/toast/helper';

export default function DashboardTemplate({ children }: PropsWithChildren) {
    const { isCollapsed } = useSidebar();
    const { onBoarded, hasAvailabilities, isLoading, setIsOpen } = useOnBoardingModal();
    const { addListener } = useSocketConnectionHook((data) => {
        console.log('Reminder Received:', data);
        showToast('success', data.message);
    });
    addListener('greet', (data) => {
        console.log('Received:', data);
    });

    let Component = <Fragment />;

    if (isLoading) {
        Component = <CenterSpinner color='primary' size='lg' />;
    } else {
        Component = (
            <div
                id='dashboard-template-content-wrapper'
                className={twJoin(
                    isCollapsed ? 'lg:ml-16' : 'lg:ml-64',
                    'mt-16 transition-all md:mt-0',
                )}
            >
                {/* <NeutralButton
                    onClick={() => {
                        socket.emit('welcome', 'test');
                    }}
                >
                    Emit
                </NeutralButton> */}
                {!hasAvailabilities ? (
                    <Flex direction='column' gapY={4} className='m-auto' alignItems='center'>
                        <Image
                            src='/svg/empty-state.svg'
                            alt='empty-state'
                            className='size-2/5'
                            width={500}
                            height={500}
                        />
                        <BodyXSmall as='span' weight='medium' color='neutral' align='center'>
                            To get started,
                            <br />
                            {!onBoarded ? (
                                <Caption color='neutral-500'>
                                    Continue with onboarding process to create availability slots
                                    and booking link
                                </Caption>
                            ) : (
                                <Caption color='neutral-500'>
                                    Continue with adding availability slots{' '}
                                    <Caption
                                        inline
                                        color='primary'
                                        className='cursor-pointer'
                                        onClick={() => setIsOpen(true)}
                                    >
                                        here
                                    </Caption>
                                </Caption>
                            )}
                        </BodyXSmall>
                    </Flex>
                ) : (
                    children
                )}
            </div>
        );
    }

    return (
        <Fragment>
            <DashboardNavbar />
            <DashboardSidebar />
            <Container
                as='main'
                id='dashboard-template'
                className='h-[calc(100vh-4rem)] min-w-full sm:h-screen'
            >
                {Component}
                <OnboardingModal />
                <ConfirmModal />
            </Container>
        </Fragment>
    );
}
