import {
    Navbar as FNavbar,
    NavbarBrand as FNavbarBrand,
    NavbarCollapse as FNavbarCollapse,
    NavbarLink as FNavbarLink,
    NavbarToggle as FNavbarToggle,
    type NavbarComponentProps,
    type NavbarBrandProps,
    type NavbarCollapseProps,
    type NavbarLinkProps,
    type NavbarToggleProps,
    type CustomFlowbiteTheme,
} from 'flowbite-react';
import Link from 'next/link';
import { forwardRef } from 'react';
// Custom Navbar theme
export const navbarTheme: CustomFlowbiteTheme['navbar'] = {};

export const NavbarComponent = forwardRef<HTMLDivElement, NavbarComponentProps>((props, ref) => {
    return <FNavbar ref={ref} {...props} theme={navbarTheme} />;
});

NavbarComponent.displayName = 'Navbar';

export const NavbarBrand = forwardRef<HTMLAnchorElement, NavbarBrandProps>((props, ref) => {
    return <FNavbarBrand ref={ref} {...props} />;
});

NavbarBrand.displayName = 'NavbarBrand';

export const NavbarCollapse = forwardRef<HTMLDivElement, NavbarCollapseProps>((props, ref) => {
    return <FNavbarCollapse ref={ref} {...props} />;
});

NavbarCollapse.displayName = 'NavbarCollapse';

export const NavbarLink = forwardRef<HTMLAnchorElement, NavbarLinkProps>((props, ref) => {
    return <FNavbarLink as={Link} ref={ref} {...props} />;
});

NavbarLink.displayName = 'NavbarLink';

export const NavbarToggle = forwardRef<HTMLButtonElement, NavbarToggleProps>((props, ref) => {
    return <FNavbarToggle ref={ref} {...props} />;
});

NavbarToggle.displayName = 'NavbarToggle';

export const Navbar = Object.assign(NavbarComponent, {
    Brand: NavbarBrand,
    Collapse: NavbarCollapse,
    Link: NavbarLink,
    Toggle: NavbarToggle,
});
