import useSWR, { SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import { AvailabilityConfiguration, CreateAvailabilityConfigurationInput } from './types';
import { postReq, fetchReq, patchReq } from '../utils/fetcher';
import { ISWRMutationConfiguration } from '@/types/types';
import { queryBuilder } from '../utils/helpers';

export const endpoint = '/api/availability';

export function useFetchAvailability(organizationId?: string, c?: SWRConfiguration) {
    const params = queryBuilder({ organizationId: organizationId });
    const url = organizationId ? endpoint.concat(`?${params}`) : endpoint;
    return useSWR<AvailabilityConfiguration, Error>(url, fetchReq, {
        ...c,
        revalidateOnFocus: false,
    });
}

export function useCreateAvailabilityConfiguration(
    c?: ISWRMutationConfiguration<
        { count: number; organizationId?: string },
        CreateAvailabilityConfigurationInput,
        any
    >,
) {
    return useSWRMutation(endpoint, postReq<CreateAvailabilityConfigurationInput>, c);
}

export function useUpdateAvailabilityConfiguration(
    c?: ISWRMutationConfiguration<AvailabilityConfiguration, AvailabilityConfiguration, any>,
) {
    return useSWRMutation(endpoint, patchReq<AvailabilityConfiguration>, c);
}

export function usePublishAvailabilityConfiguration(
    c?: ISWRMutationConfiguration<boolean, { isPublished: boolean; organizationId?: string }, any>,
) {
    const url = endpoint.concat('/publish');
    return useSWRMutation(url, patchReq<{ isPublished: boolean; organizationId?: string }>, c);
}
