import {
    Sidebar as FSidebar,
    SidebarItem as FSidebarItem,
    SidebarCollapse as FSidebarCollapse,
    SidebarItemGroup as FSidebarItemGroup,
    SidebarItems as FSidebarItems,
    SidebarCTA as FSidebatCTA,
    type SidebarProps,
    type SidebarItemProps,
    type SidebarItemGroupProps,
    type SidebarCTAProps,
    type SidebarItemsProps,
    type SidebarCollapseProps,
    type CustomFlowbiteTheme,
} from 'flowbite-react';
import { forwardRef } from 'react';
import { SidebarLogo } from './sidebar-logo';

// Custom Sidebar theme
export const sidebarTheme: CustomFlowbiteTheme['sidebar'] = {
    root: {
        base: 'fixed inset-y-0 left-0 z-20 h-full border-r transition-all transform md:translate-x-0',
        collapsed: {
            on: 'w-16 transition-all -translate-x-full',
            off: 'w-64 md:translate-x-0',
        },
        inner: 'h-full overflow-y-auto overflow-x-hidden rounded bg-neutral-50 px-3 py-4 dark:bg-gray-800',
    },
    item: {
        base: 'flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-primary-100 dark:text-white dark:hover:bg-gray-700',
        active: 'bg-primary-100 rounded-lg text-primary-600 dark:bg-gray-700',
        icon: {
            base: 'h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
            active: 'text-primary-700 dark:text-gray-100',
        },

        // listItem: 'w-full',
    },
    items: {
        base: ' mt-1',
    },
    logo: {
        base: 'flex items-center pl-0',
        img: 'h-6 sm:h-10',
    },
};

// Main sidebar component
export const SidebarComponent = forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
    return <FSidebar ref={ref} {...props} theme={sidebarTheme} />;
});

SidebarComponent.displayName = 'Sidebar';

// Sidebar items component
export const SidebarItems = forwardRef<HTMLDivElement, SidebarItemsProps>((props, ref) => {
    return <FSidebarItems ref={ref} {...props} />;
});

SidebarItems.displayName = 'SidebarItems';

// Sidebar item component
export const SidebarItem = forwardRef<HTMLLIElement, SidebarItemProps>((props, ref) => {
    return <FSidebarItem ref={ref} {...props} />;
});

SidebarItem.displayName = 'SidebarItem';

// Sidebar item group component
export const SidebarItemGroup = forwardRef<HTMLUListElement, SidebarItemGroupProps>(
    (props, ref) => {
        return <FSidebarItemGroup ref={ref} {...props} />;
    },
);

SidebarItemGroup.displayName = 'SidebarItemGroup';

// Sidebar collapse component
export const SidebarCollapse = forwardRef<HTMLButtonElement, SidebarCollapseProps>((props, ref) => {
    return <FSidebarCollapse ref={ref} {...props} />;
});

SidebarCollapse.displayName = 'SidebarCollapse';

// Sidebar CTA component
export const SidebarCTA = forwardRef<HTMLDivElement, SidebarCTAProps>((props, ref) => {
    return <FSidebatCTA ref={ref} {...props} />;
});

SidebarCTA.displayName = 'SidebarCTA';

export const Sidebar = Object.assign(SidebarComponent, {
    Logo: SidebarLogo,
    Items: SidebarItems,
    Item: SidebarItem,
    ItemGroup: SidebarItemGroup,
    Collapse: SidebarCollapse,
    CTA: SidebarCTA,
});
