import { PropsWithChildren } from 'react';
import { TextProps, Text } from '../text';

type TypographyProps = TextProps;

export const DisplayLarge: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h1'} size='display-lg' weight='bold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const Display: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h1'} size='display' weight='bold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const HeadingLarge: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h2'} size='heading-lg' weight='semibold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const HeadingMedium: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h3'} size='heading-md' weight='semibold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const HeadingSmall: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h4'} size='heading-sm' weight='semibold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const HeadingXSmall: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h5'} size='heading-xs' weight='semibold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const Title: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h6'} size='title' weight='semibold' color='neutral-heading' {...props}>
            {children}
        </Text>
    );
};

export const BodyLarge: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'h6'} size='body-lg' weight='medium' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const BodyMedium: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'p'} size='body-md' weight='normal' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const BodySmall: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'p'} size='body-sm' weight='normal' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const BodyXSmall: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'p'} size='body-xs' weight='normal' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const Caption: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'p'} size='caption' weight='normal' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const CaptionSmall: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'p'} size='caption-sm' weight='normal' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const Overline: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as={'p'} size='overline' weight='normal' color='neutral-content' {...props}>
            {children}
        </Text>
    );
};

export const HeadingOneAlpha: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as='h1' size='h1-alpha' weight='bold' lineHeight='10' {...props}>
            {children}
        </Text>
    );
};

export const HeadingTwoBeta: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as='h2' size='h2-beta' weight='bold' lineHeight='5' {...props}>
            {children}
        </Text>
    );
};

export const HeadingThreeDelta: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as='h3' size='h3-delta' weight='medium' lineHeight='5' {...props}>
            {children}
        </Text>
    );
};

export const BodyOmega: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <Text as='div' size='body-omega' lineHeight='5' {...props}>
            {children}
        </Text>
    );
};

export const ErrorLabel: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Text as='div' size='caption' color='error'>
            {children}
        </Text>
    );
};
