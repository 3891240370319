import { TextInput as TextFieldInput } from '@/components/atoms/text-input';
import { InputWrapper } from '../wrapper/input-wrapper';
import { FieldProps } from 'formik';
import { getErrorMessage } from '@/libs/utils/helpers';

type TextInputProps = FieldProps & {
    id: string;
    label: string;
    name: string;
    placeholder: string;
    required: boolean;
    value: string;
};
export const TextInput: React.FC<TextInputProps> = ({ id, label, field, form, ...props }) => {
    const error = getErrorMessage(field, form);
    return (
        <InputWrapper htmlFor={props.name || field.name} value={label} error={error}>
            <TextFieldInput
                id={id || props.name || field.name}
                color={error ? 'failure' : undefined}
                helperText={error}
                {...field}
                {...props}
            />
        </InputWrapper>
    );
};

export const TextAreaInput: React.FC<TextInputProps> = ({ id, label, field, form, ...props }) => {
    const error = getErrorMessage(field, form);
    return (
        <InputWrapper htmlFor={props.name} value={label} error={error}>
            <TextFieldInput
                as='textarea'
                id={id || props.name || field.name}
                color={error ? 'failure' : undefined}
                helperText={error}
                {...field}
                {...props}
            />
        </InputWrapper>
    );
};
