'use client';
import Navbar from '@/components/atoms/navbar';
import { isSmallScreen } from '@/libs/utils/helpers';
import Image from 'next/image';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';

type TopNavbarProps = {
    toggleSidebar: () => void;
    isSidebarCollapsed: boolean;
};

export default function TopNavbar(props: TopNavbarProps) {
    const { toggleSidebar, isSidebarCollapsed } = props;
    return (
        <header>
            <Navbar
                fluid
                className='fixed top-0 z-30 block w-full border-b border-gray-200 bg-white p-0 dark:border-gray-700 dark:bg-gray-800 sm:p-0 md:hidden'
            >
                <div className='w-full p-3 pr-4'>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center'>
                            <button
                                aria-controls='sidebar'
                                aria-expanded
                                className='mr-2 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700 dark:focus:ring-gray-700'
                                onClick={() => toggleSidebar()}
                            >
                                {isSidebarCollapsed || !isSmallScreen() ? (
                                    <Bars3Icon className='size-6' />
                                ) : (
                                    <XMarkIcon className='size-6' />
                                )}
                            </button>
                            <Navbar.Brand href='/'>
                                <Image
                                    alt='Skedule AI logo'
                                    height='24'
                                    src='/svg/logo-rect.svg'
                                    width='90'
                                />
                            </Navbar.Brand>
                        </div>
                        {/* <DarkThemeToggle /> */}
                    </div>
                </div>
            </Navbar>
        </header>
    );
}
