import { io, Socket } from 'socket.io-client';
import { SocketEvent } from './enums';

// Typing for client-side Socket.io
type ClientSocketIO = Socket;
type Listener = (...args: any[]) => void;

class SocketClient {
    private static instance: SocketClient;
    private socket: ClientSocketIO | null = null;
    private listeners = new Map<string, Listener>();

    private constructor() {}

    public static getInstance(): SocketClient {
        if (!SocketClient.instance) {
            SocketClient.instance = new SocketClient();
        }
        return SocketClient.instance;
    }

    public async connect(
        url: string,
        event?: SocketEvent | string,
        listener?: Listener,
    ): Promise<void> {
        await fetch('/api/socketio');
        if (this.socket) return; // Avoid re-initialization

        const _socket = io(url, {
            path: '/api/socketio',
            transports: ['websocket', 'polling'],
            reconnectionDelay: 1000,
            reconnection: true,
            reconnectionAttempts: 10,
            upgrade: false,
            rejectUnauthorized: false,
        });
        _socket.on('connect', () => {
            if (_socket.connected) {
                console.log('Socket Client Connected', _socket.id);
                if (listener && event) {
                    this.addListener(event, listener);
                }
            }
        });

        _socket.on('disconnect', () => {
            console.log('Socket Client Disconnected: ');
        });

        _socket.on('connect_error', (err) => {
            console.log(`connect_error due to ${err}`);
        });

        // Save the socket instance
        this.socket = _socket;
    }

    public getSocket(): ClientSocketIO {
        if (!this.socket) {
            const _msg = 'Socket is not connected. Call connect() first.';
            console.warn(_msg);
            throw new Error(_msg);
        }
        return this.socket;
    }
    // Method to dynamically add listeners for specific events
    public addListener(event: SocketEvent | string, listener: Listener): void {
        if (this.socket && this.socket.connected) {
            // Remove any existing listener for the event to avoid duplicates
            if (this.listeners.has(event)) {
                this.socket.off(event, this.listeners.get(event)!);
            }
            // Add new listener and track it in the map
            this.socket.on(event, listener);
            this.listeners.set(event, listener);
            console.log(`Listener added for event: ${event}`);
        } else {
            console.warn('Socket is not connected. Call connect() before adding listeners.');
        }
    }

    public disconnect(): void {
        this.socket?.disconnect();
        this.socket = null;
        this.listeners.clear();
    }
}
// const socketClient = SocketClient.getInstance();
export default SocketClient;
