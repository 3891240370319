import {
    Modal as FModal,
    ModalBody as FModalBody,
    ModalHeader as FModalHeader,
    ModalFooter as FModalFooter,
    type ModalProps,
    type ModalBodyProps,
    type ModalHeaderProps,
    type ModalFooterProps,
    CustomFlowbiteTheme,
} from 'flowbite-react';
import { forwardRef } from 'react';

// Custom Modal theme
const modalTheme: CustomFlowbiteTheme['modal'] = {
    content: {
        base: 'relative h-full w-full p-4 md:h-auto focus-visible:outline-none',
    },
};

// Main modal component
export const ModalComponent = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
    return <FModal {...props} theme={modalTheme} ref={ref} />;
});

ModalComponent.displayName = 'Modal';

// Modal body component
export const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>((props, ref) => {
    return <FModalBody {...props} ref={ref} />;
});

ModalBody.displayName = 'ModalBody';

// Modal header component
export const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>((props, ref) => {
    return <FModalHeader {...props} ref={ref} />;
});

ModalHeader.displayName = 'ModalHeader';

// Modal footer component
export const ModalFooter = forwardRef<HTMLDivElement, ModalFooterProps>((props, ref) => {
    return <FModalFooter {...props} ref={ref} />;
});

ModalFooter.displayName = 'ModalFooter';

export const Modal = Object.assign(ModalComponent, {
    Body: ModalBody,
    Header: ModalHeader,
    Footer: ModalFooter,
});
