import * as Yup from 'yup';
import { ServiceProviderType } from '../enums';

export const getOnboardingSchema = (step: number, type: ServiceProviderType) => {
    const organizationDetails =
        type === ServiceProviderType.ORGANIZATION
            ? {
                  organizationName: Yup.string()
                      .min(2, 'Minimum 2 characters required')
                      .required('Required'),
              }
            : {
                  name: Yup.string(),
              };

    const stepOneFields = {
        ...organizationDetails,
        timezone: Yup.string().min(2, 'Minimum 2 characters required').required('Required'),
        currency: Yup.string().min(1, 'Minimum 1 characters required').required('Required'),
    };

    const stepTwoFields = {
        startTime: Yup.string().required('Business hours are required'),
        endTime: Yup.string().required('Business hours are required'),
        duration: Yup.number()
            .required('Meeting Duration is required')
            .min(1, 'At least one slot is required'),
        days: Yup.array()
            .of(Yup.number().required())
            .required('At least one business day is required')
            .test('value-check', 'At least one business day is required', (value) => {
                const errorValue = value.find(
                    (val) => typeof val === 'number' && val > 0 && val < 8,
                );
                if (value.length || errorValue) {
                    return true;
                }
                return false;
            }),
    };

    const fields =
        step === 2
            ? Yup.object().shape({
                  ...(stepOneFields as unknown as any),
                  ...stepTwoFields,
              })
            : Yup.object().shape(stepOneFields as unknown as any);

    return fields;
};
