'use client';
import { NeutralButton } from '@/components/atoms/button';
import { Flex } from '@/components/atoms/flex';
import {
    Sidebar,
    SidebarLogo,
    SidebarItems,
    SidebarItem,
    SidebarItemGroup,
} from '@/components/atoms/sidebar';
import { OrganizationSwitcher, SignOutButton } from '@clerk/nextjs';
import {
    ArrowLeftEndOnRectangleIcon,
    ChevronRightIcon,
    Cog8ToothIcon,
    HomeIcon,
} from '@heroicons/react/24/solid';
import { PropsWithChildren } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
// import LeftNavCTA from './left-nav-cta';
import { Divider } from '@/components/atoms/divider';
import Link from 'next/link';
import { SidebarItemOption } from '@/types/types';
import useOnBoardingModal from '@/libs/hooks/useOnBoardingModal';
import { usePathname } from 'next/navigation';
import { SettingsTab } from '@/libs/enums';

export type LeftSidebarProps = {
    isCollapsed: boolean;
    options?: SidebarItemOption[];
    toggleSidebar?: () => void;
} & PropsWithChildren;
export default function LeftSidebar(props: LeftSidebarProps) {
    const path = usePathname();
    const { isCollapsed, options, toggleSidebar } = props;
    const { hasAvailabilities } = useOnBoardingModal();
    let navObject = {
        label: 'Settings',
        key: 'settings',
        href: `/settings?tab=${SettingsTab.CONFIGURATION}`,
        icon: Cog8ToothIcon,
    };
    if (path?.includes('settings')) {
        navObject = {
            label: 'Dashboard',
            key: 'dashboard',
            href: '/dashboard',
            icon: HomeIcon,
        };
    }

    return (
        <Sidebar id='left-Sidebar' collapsed={isCollapsed} className='group/crlBtn mt-8 md:mt-0'>
            <Flex
                wrap={isCollapsed}
                className='mb-4 hidden md:flex'
                gap={1}
                alignItems='center'
                justifyContent='center'
            >
                <SidebarLogo
                    img={isCollapsed ? '/svg/logo-sqr.svg' : '/svg/logo-rect.svg'}
                    href='/'
                    isCollapsed={isCollapsed}
                    className={twMerge(isCollapsed ? '[&>img]:w-full' : 'w-fit [&>img]:w-10/12')}
                />
                <NeutralButton
                    size='xs'
                    onClick={() => toggleSidebar?.()}
                    className={twJoin(
                        'absolute -right-3.5 top-12 rounded-full shadow [&>span]:p-1',
                        'invisible group-hover/crlBtn:visible',
                    )}
                >
                    <ChevronRightIcon
                        className={twJoin(
                            'size-3.5 transition-[transform] ease-in',
                            !isCollapsed ? 'invisible rotate-180 group-hover/crlBtn:visible' : '',
                        )}
                    />
                </NeutralButton>
            </Flex>
            <Divider className='my-2' />
            {options && (
                <Flex direction='column' className='h-[91%]' justifyContent='between'>
                    <SidebarItems className=''>
                        <SidebarItemGroup key={'group-1'}>
                            {options.map((option) => (
                                <SidebarItem
                                    as={Link}
                                    key={option.key}
                                    href={option.href ?? '#'}
                                    target={option.target ?? '_self'}
                                    label={option.label}
                                    icon={option.icon as any}
                                    active={option.isActive}
                                    onClick={option.onClick}
                                >
                                    {option.text}
                                </SidebarItem>
                            ))}
                        </SidebarItemGroup>
                    </SidebarItems>
                    <SidebarItems>
                        {/* {!isCollapsed && (
                            <LeftNavCTA
                                title='John Doe'
                                startTime='5:30PM'
                                endTime='6:30PM'
                                meetingLink=''
                            />
                        )} */}
                        <SidebarItemGroup key={'group-2'} className=''>
                            {!isCollapsed && (
                                <SidebarItem>
                                    <OrganizationSwitcher />
                                </SidebarItem>
                            )}
                            {hasAvailabilities && (
                                <SidebarItem
                                    as={Link}
                                    key={navObject.key}
                                    href={navObject.href}
                                    icon={navObject.icon}
                                >
                                    {navObject.label}
                                </SidebarItem>
                            )}
                            <SidebarItem key={'sign-out'} icon={ArrowLeftEndOnRectangleIcon}>
                                <SignOutButton>Log Out</SignOutButton>
                            </SidebarItem>
                        </SidebarItemGroup>
                    </SidebarItems>
                </Flex>
            )}
        </Sidebar>
    );
}
