import React from 'react';
import { CustomFlowbiteTheme, Label as FLabel, type LabelProps } from 'flowbite-react';

export const labelTheme: CustomFlowbiteTheme['label'] = {
    root: {
        base: 'text-sm font-medium',
        colors: {
            'neutral-950': 'text-neutral-950 dark:text-neutral-300',
            'neutral-700': 'text-neutral-700 dark:text-neutral-300',
        },
    },
};

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
    const { color = 'neutral-700', ...rest } = props;
    return <FLabel ref={ref} color={color} {...rest} theme={labelTheme} />;
});

Label.displayName = 'Label';

export default Label;
