'use client';
import { DangerButton, NeutralButton } from '@/components/atoms/button';
import { Modal } from '@/components/atoms/modal';
import { CaptionSmall, HeadingSmall } from '@/components/atoms/typography';
import { insertComponentsIntoText } from '@/libs/utils/helpers';
import { dashboardAction } from '@/libs/valtio/actions/dashboard-action';
import { dashboardState } from '@/libs/valtio/states/dashboard-state';
import { useSnapshot } from 'valtio';

function ConfirmModal() {
    const {
        show,
        data: { title, message, isProcessing, highlight, onConfirm },
    } = useSnapshot(dashboardState.confirmModal);

    function close() {
        dashboardAction.setShowConfirmModal(false);
    }
    if (show) {
        return (
            <Modal show={show} dismissible onClose={close} size='lg'>
                <Modal.Body>
                    <HeadingSmall>{title}</HeadingSmall>
                    <CaptionSmall className='mt-2'>
                        {insertComponentsIntoText(message!, {
                            highlight: <b key='highlight'>{highlight}</b>,
                        })}
                    </CaptionSmall>
                </Modal.Body>
                <Modal.Footer className='justify-end'>
                    <NeutralButton disabled={isProcessing} onClick={close}>
                        Cancel
                    </NeutralButton>
                    <DangerButton
                        isProcessing={isProcessing}
                        disabled={isProcessing}
                        onClick={onConfirm}
                    >
                        Confirm
                    </DangerButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmModal;
