import { tv, type VariantProps } from 'tailwind-variants';
import { type ElementType } from 'react';

const textVariants = tv(
    {
        base: 'text-base font-normal',
        variants: {
            size: {
                'display-lg': 'text-4xl md:text-6xl',
                display: 'text-3xl md:text-4xl',
                'heading-lg': 'text-2xl md:text-3xl ',
                'heading-md': 'text-xl md:text-2xl ',
                'heading-sm': 'md:text-xl',
                'heading-xs': 'md:text-lg',
                title: 'text-sm md:text-base',
                'body-lg': 'text-lg md:text-xl',
                'body-md': 'text-base md:text-lg',
                'body-sm': 'text-sm md:text-base',
                'body-xs': 'text-xs md:text-sm',
                caption: 'text-xs md:text-sm',
                'caption-sm': 'text-[10px] md:text-xs lg:text-sm',
                overline: 'text-xs md:text-sm lg:text-sm',
                'h1-alpha': 'text-4xl',
                'h2-beta': 'text-lg',
                'h3-delta': 'text-base',
                'body-omega': 'text-sm',
            },
            weight: {
                extraLight: 'font-extralight',
                light: 'font-light',
                normal: 'font-normal',
                medium: 'font-medium',
                semibold: 'font-semibold',
                bold: 'font-bold',
                extraBold: 'font-extrabold',
            },
            color: {
                primary: 'text-blue-700',
                neutral: 'text-neutral-700',
                success: 'text-success-600',
                error: 'text-red-500',
                black: 'text-black',
                'neutral-500': 'text-neutral-500',
                'neutral-heading': 'text-neutral-900',
                'neutral-content': 'text-neutral-600',
            },
            align: {
                center: 'text-center',
                left: 'text-left',
                right: 'text-right',
                justify: 'text-justify',
            },
            truncate: {
                true: 'truncate',
            },
            lineHeight: {
                '10': 'leading-10',
                '5': 'leading-5',
            },
            inline: {
                true: 'inline',
            },
        },
    },
    { responsiveVariants: ['sm'] },
);

type TextProps = React.ComponentPropsWithRef<
    'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'a'
> &
    VariantProps<typeof textVariants> & {
        as?: ElementType;
        htmlFor?: string;
    };

export { type TextProps, textVariants };
