import { type ElementType } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

export const containerVariants = tv(
    {
        base: 'container',
        variants: {
            center: {
                true: 'mx-auto',
            },

            fullWidth: {
                true: 'w-full',
            },

            fullscreen: {
                true: 'h-screen min-w-full',
                false: 'h-auto w-full',
            },
        },
    },
    {
        responsiveVariants: ['sm'], // `true` to apply to all screen sizes
    },
);

export type ContainerProps = React.ComponentPropsWithRef<'div'> &
    VariantProps<typeof containerVariants> & {
        as?: ElementType;
    };
