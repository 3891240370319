import { getTodayDate } from '@/libs/utils/datetime-helpers';
import { proxy } from 'valtio';
export type DashboardState = {
    sidebar: {
        collapsed: boolean;
    };
    appointment: {
        queryDate: Date;
    };
    confirmModal: {
        show: boolean;
        data: {
            title?: string;
            message?: string;
            isProcessing?: boolean;
            highlight?: any;
            onConfirm?: () => void;
        };
    };
    onBoardingModal: {
        show: boolean;
    };
};

export const dashboardState = proxy<DashboardState>({
    sidebar: {
        collapsed: false,
    },
    appointment: {
        queryDate: getTodayDate(),
    },
    confirmModal: {
        show: false,
        data: {
            title: 'Confirmation required',
            message: 'Are you sure you want to do this?',
            isProcessing: false,
        },
    },
    onBoardingModal: {
        show: false,
    },
});
