import type { FC, ReactNode } from 'react';
import { useId } from 'react';
import { twMerge } from 'tailwind-merge';

import { type FlowbiteSidebarLogoTheme, getTheme } from 'flowbite-react';
import { type DeepPartial } from '@/types/types';
import { mergeDeep } from '@/libs/utils/helpers';
import Image from 'next/image';
import Link, { type LinkProps } from 'next/link';

export interface SidebarLogoProps extends LinkProps {
    href: string;
    img: string;
    imgAlt?: string;
    theme?: DeepPartial<FlowbiteSidebarLogoTheme>;
    isCollapsed: boolean;
    children?: ReactNode;
    className?: string;
}

export const SidebarLogo: FC<SidebarLogoProps> = ({
    children,
    className,
    href,
    img,
    imgAlt = '',
    theme: customTheme = {},
    isCollapsed,
    ...props
}) => {
    const id = useId();

    const theme = mergeDeep(getTheme().sidebar.logo, customTheme);

    return (
        <Link
            aria-labelledby={`flowbite-sidebar-logo-${id}`}
            href={href}
            className={twMerge(theme.base, className)}
            {...props}
        >
            <Image alt={imgAlt} src={img} height={24} width={24} className={theme.img} />
            <span
                className={theme.collapsed[isCollapsed ? 'on' : 'off']}
                id={`flowbite-sidebar-logo-${id}`}
            >
                {children}
            </span>
        </Link>
    );
};

SidebarLogo.displayName = 'SidebarLogo';

export default SidebarLogo;
