import {
    getDateFromTimeZone,
    getDaysOptions,
    getFormattedTimeZones,
    getTodayDate,
    getTodayTimeString,
} from '../utils/datetime-helpers';
import { getCurrencyOptions } from '../utils/helpers';
import { Slot, TimeSlot } from '../api/types';
import capitalize from 'lodash/capitalize';

export type FormFieldType = {
    name: string;
    label: string;
    type: string;
    placeholder: string;
    required?: boolean;
    disabled?: boolean;
};

export const Fields = {
    NAME: {
        name: 'name',
        label: 'Your name',
        type: 'text',
        placeholder: 'Enter your name',
    },
    ORGANIZATION_NAME: {
        name: 'organizationName',
        label: 'Organization name',
        type: 'text',
        placeholder: 'Enter your organization name',
    },
    ORGANIZATION_TYPE: {
        name: 'typeOfOrganization',
        label: 'Type of organization',
        type: 'text',
        placeholder: 'Enter your organization type',
    },
    SERVICES_OFFERED: {
        name: 'servicesOffered',
        label: 'Services offered',
        type: 'text',
        placeholder: 'Enter your services offered',
    },
    ABOUT_ORGANIZATION: {
        name: 'aboutOrganization',
        label: 'About the organization',
        type: 'textarea',
        placeholder: 'Enter your organization description',
    },
    IMAGE: {
        name: 'imageUrl',
        label: 'Image',
        type: 'file',
        placeholder: 'Upload image',
    },
    TIMEZONE: {
        name: 'timezone',
        label: 'Select timezone',
        type: 'select',
        placeholder: 'Select your time zone',
        required: true,
        options: getFormattedTimeZones(),
    },
    CURRENCY: {
        name: 'currency',
        label: 'Select currency',
        type: 'select',
        placeholder: 'Select your currency',
        required: true,
        options: getCurrencyOptions(),
    },
    PRICE: {
        name: 'price',
        label: 'Price',
        type: 'number',
        placeholder: 'Enter price',
        required: true,
    },
    WORKING_HOURS: {
        name: 'businessHours',
        label: 'Business hours',
        type: 'time-range',
        placeholder: '',
        required: true,
        startTimeField: {
            name: 'startTime',
        },
        endTimeField: {
            name: 'endTime',
        },
    },
    SLOT_DURATION: {
        name: 'duration',
        label: 'Meeting Duration',
        type: 'number',
        placeholder: 'Enter meeting duration',
        required: true,
    },
    WORKING_DAYS: {
        name: 'days',
        label: 'Business Days',
        type: 'button-select',
        placeholder: '',
        required: true,
        options: getDaysOptions(),
    },
    EARLIEST_BOOKING_WINDOW: {
        name: 'earliestBookingWindow',
        label: 'Earliest booking window',
        type: 'number',
        placeholder: 'Your earliest booking window',
        required: false,
    },
    SHORTEST_BOOKING_NOTICE: {
        name: 'shortestBookingNotice',
        label: 'Shortest booking notice',
        type: 'number',
        placeholder: 'Your shortest booking notice',
        required: false,
    },
    BUFFER_TIME: {
        name: 'bufferDuration',
        label: 'Buffer time',
        type: 'number',
        placeholder: 'Add buffer time in minutes',
        required: false,
    },
    MESSAGE: {
        name: 'greetingMessage',
        label: 'Greeting Message',
        type: 'textarea',
        placeholder: 'type your customized message here...',
        required: false,
    },
};

export const getTimeZoneFromTimeSlots = (data: TimeSlot[]) => {
    let zone: string = '';
    data.map((timeSlot) => {
        if (timeSlot.slots.length > 0) {
            zone = timeSlot.timezone;
        }
    });
    return zone;
};

export const getSlots = (toZone: string, data?: Slot[], date: Date = getTodayDate()) => {
    const slots: Array<{ label: string; value: string; disabled?: boolean }> = [];
    data?.map((slot) => {
        const start = getDateFromTimeZone(slot.startTime, slot.timezone, toZone);
        const end = getDateFromTimeZone(slot.endTime, slot.timezone, toZone);
        const fSlot = `${start} - ${end}`;
        slots.push({
            label: fSlot,
            value: fSlot,
            disabled:
                (date?.getDate() === getTodayDate().getDate() &&
                    getTodayTimeString(slot.timezone) >= start) ||
                slot.disabled,
        });
    });

    return slots;
};

export const getSettingConfigurationFormFields = () => {
    const daysOptions = getDaysOptions();
    const daysFields = daysOptions.map((dayOption) => {
        return {
            label: capitalize(dayOption.label),
            name: dayOption.value.toString(),
            type: 'schedule',
            placeholder: '',
            required: false,
            startTimeField: {
                name: 'startTime',
            },
            endTimeField: {
                name: 'endTime',
            },
        };
    });
    return [
        {
            id: 'settingsConfigurationLeftSectionFields',
            fields: [Fields.SLOT_DURATION, Fields.TIMEZONE, ...daysFields],
        },
        {
            id: 'settingsConfigurationRightSectionFields',
            fields: [
                Fields.EARLIEST_BOOKING_WINDOW,
                Fields.SHORTEST_BOOKING_NOTICE,
                Fields.BUFFER_TIME,
                Fields.MESSAGE,
            ],
        },
    ];
};

export const settingConfigurationPartialsFields = [
    Fields.SLOT_DURATION.name,
    Fields.TIMEZONE.name,
    Fields.EARLIEST_BOOKING_WINDOW.name,
    Fields.SHORTEST_BOOKING_NOTICE.name,
    Fields.BUFFER_TIME.name,
];

export const getOrganizationOverviewFormFields = () => {
    return [
        { ...Fields.ORGANIZATION_NAME, name: 'name' },
        Fields.ORGANIZATION_TYPE,
        Fields.SERVICES_OFFERED,
        Fields.ABOUT_ORGANIZATION,
        Fields.IMAGE,
    ];
};

export const getPayoutFormFields = () => {
    return [Fields.CURRENCY, Fields.PRICE];
};
