'use client';

import LeftSidebar from '@/components/molecules/navbar/left-sidebar';
import useSidebar from '@/libs/hooks/use-sidebar';

export default function DashboardSidebar() {
    const { itemOptions, isCollapsed, toggleSidebar } = useSidebar();
    return (
        <LeftSidebar
            isCollapsed={isCollapsed}
            toggleSidebar={toggleSidebar}
            options={itemOptions}
        />
    );
}
