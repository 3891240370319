import React from 'react';
import { DividerProps, dividerVariants } from './divider.variants';

// Divider main component
export function Divider(props: DividerProps) {
    const { as = 'hr', id = 'divider', vertical, color, thick, className, ...rest } = props;
    return React.createElement(as, {
        id,
        ...rest,
        className: dividerVariants({ vertical, color, thick, className }),
    });
}

Divider.displayName = 'Divider';

export default Divider;
