import React from 'react';
import type { ComponentProps, FC, ReactNode } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { HelperText } from 'flowbite-react';
import {
    type FlowbiteTextInputColors,
    type FlowbiteTextInputSizes,
    type FlowbiteTextInputTheme,
} from 'flowbite-react';
import { mergeDeep } from '@/libs/utils/helpers';
import { DynamicStringEnumKeysOf, type DeepPartial } from '@/types/types';
import { textInputTheme } from './theme';

export interface TextInputProps extends Omit<ComponentProps<'input'>, 'ref' | 'color'> {
    addon?: ReactNode;
    color?: DynamicStringEnumKeysOf<FlowbiteTextInputColors>;
    helperText?: ReactNode;
    icon?: FC<ComponentProps<'svg'>>;
    rightIcon?: FC<ComponentProps<'svg'>>;
    shadow?: boolean;
    sizing?: keyof FlowbiteTextInputSizes;
    theme?: DeepPartial<FlowbiteTextInputTheme>;
    renderRightIcon?: ({ className }: { className?: string }) => ReactNode;
}
export const TextInput = forwardRef<
    HTMLInputElement,
    TextInputProps & {
        as?: string;
        renderRightIcon?: ({ className }: { className?: string }) => ReactNode;
    }
>(
    (
        {
            as = 'input',
            addon,
            className,
            color = 'neutral',
            helperText,
            icon: Icon,
            rightIcon: RightIcon,
            shadow,
            sizing = 'md',
            theme: customTheme = {},
            type = 'text',
            renderRightIcon,
            ...props
        },
        ref,
    ) => {
        const theme = mergeDeep(textInputTheme, customTheme);
        const inputElement = React.createElement(as, {
            id: props.id,
            name: props.name,
            className: twMerge(
                theme.field.input.base,
                theme.field.input.colors[color],
                theme.field.input.sizes[sizing],
                theme.field.input.withIcon[Icon ? 'on' : 'off'],
                theme.field.input.withRightIcon[RightIcon ? 'on' : 'off'],
                theme.field.input.withAddon[addon ? 'on' : 'off'],
                theme.field.input.withShadow[shadow ? 'on' : 'off'],
            ),
            type,
            ...props,
            ref,
        });
        return (
            <>
                <div className={twMerge(theme.base, className)}>
                    {addon && <span className={theme.addon}>{addon}</span>}
                    <div className={theme.field.base}>
                        {Icon && (
                            <div className={theme.field.icon.base}>
                                <Icon className={theme.field.icon.svg} />
                            </div>
                        )}
                        {RightIcon && (
                            <div
                                data-testid='right-icon'
                                id={props.id}
                                className={theme.field.rightIcon.base}
                            >
                                <RightIcon id={props.id} className={theme.field.rightIcon.svg} />
                            </div>
                        )}
                        {renderRightIcon && (
                            <div
                                data-testid='right-icon'
                                id={props.id}
                                className={theme.field.rightIcon.base}
                            >
                                {renderRightIcon({ className: theme.field.rightIcon.svg })}
                            </div>
                        )}
                        {inputElement}
                    </div>
                </div>
                {helperText && (
                    <HelperText className='mt-0' color={color}>
                        {helperText}
                    </HelperText>
                )}
            </>
        );
    },
);

TextInput.displayName = 'TextInput';
