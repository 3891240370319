import { Tabs } from '@/components/atoms/tabs';
import { ServiceProviderType } from '@/libs/enums';

export const serviceProviderTypes = [
    { label: 'Organization', value: ServiceProviderType.ORGANIZATION },
    { label: 'Individual', value: ServiceProviderType.INDIVIDUAL },
];

export type ServiceProviderTypeProps = {
    onActiveTabChange?: (activeTab: number) => void;
};

const ServiceProviderTypeSwitch: React.FC<ServiceProviderTypeProps> = ({ onActiveTabChange }) => {
    const tabItems = serviceProviderTypes.map((item) => (
        <Tabs.Item key={item.value} title={item.label} />
    ));
    return (
        <Tabs
            onActiveTabChange={onActiveTabChange}
            variant='underline'
            className='items-center [&>div:nth-child(2)]:hidden'
        >
            {tabItems}
        </Tabs>
    );
};

export default ServiceProviderTypeSwitch;
