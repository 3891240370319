import React from 'react';
import { Flex } from '@/components/atoms/flex';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { OrganizationFormFields } from './fields';
import { PrimaryButton } from '@/components/atoms/button';
import { getOnboardingSchema } from '@/libs/validations';
import { HelperText } from 'flowbite-react';
import { ServiceProviderType } from '@/libs/enums';

const onboardingFormTemplate = (fields: any[]) => {
    return (props: FormikProps<any>) => {
        return (
            <Form>
                <Flex direction='column' gapY={4}>
                    <OrganizationFormFields fields={fields} />
                    <Flex direction='column' alignSelf='center' alignItems='center' gapY={1}>
                        <HelperText color='failure' hidden={!props.status?.submitError}>
                            {props.status?.submitError}
                        </HelperText>
                        <PrimaryButton
                            type='submit'
                            outline={true}
                            size='md'
                            isProcessing={props.isSubmitting}
                        >
                            Continue
                        </PrimaryButton>
                    </Flex>
                </Flex>
            </Form>
        );
    };
};

const OnboardingForm: React.FC<{
    formRef: any;
    initialValues?: any;
    fields: any[];
    step: number;
    formType: ServiceProviderType;
    handleSubmit?: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
}> = ({ formRef, fields, step, formType, handleSubmit, initialValues }) => {
    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={getOnboardingSchema(step, formType)}
            enableReinitialize
            onSubmit={(values, formikHelper) => {
                handleSubmit?.(values, formikHelper);
            }}
        >
            {onboardingFormTemplate(fields)}
        </Formik>
    );
};

export default OnboardingForm;
