import { useEffect } from 'react';

import { SocketEvent } from '../enums';
import SocketClient from '../socketClient';

const useSocketConnectionHook = (
    listener?: (...args: any[]) => void,
    ev: SocketEvent | string = SocketEvent.toast,
) => {
    const socketClient = SocketClient.getInstance();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            socketClient.connect(window.location.origin, ev, listener);
        }

        return () => {
            socketClient.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addListener = (newEvent: SocketEvent | string, listener: (...args: any[]) => void) => {
        socketClient.addListener(newEvent, listener);
    };

    return { addListener };
};

export default useSocketConnectionHook;
