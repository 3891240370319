import useSWR, { SWRConfiguration } from 'swr';
import { CreateUserConfigurationArgs } from './types';
import fetchReq, { postReq } from '../utils/fetcher';
import useSWRMutation from 'swr/mutation';
import { ISWRMutationConfiguration } from '@/types/types';

const endpoint = '/api/user_configuration';

export function useFetchUserConfigurationQuery(c?: SWRConfiguration) {
    return useSWR<{
        userConfiguration: {
            onBoardingModal: boolean;
        };
    }>(endpoint, fetchReq, {
        ...c,
        revalidateOnFocus: false,
        keepPreviousData: true,
    });
}

export function useUpdateUserConfigurationMutation(
    c?: ISWRMutationConfiguration<any, CreateUserConfigurationArgs, any>,
) {
    return useSWRMutation(endpoint, postReq<CreateUserConfigurationArgs>, c);
}
