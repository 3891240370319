export const ErrorMessages = {
    UNAUTHORIZED: 'Unauthorized',
    INVALID_INPUT: 'Invalid input.',
    AVAILABILITY_CONFIG_CREATE_ERROR: 'Availability configuration for user already exists.',
    BOOKING_CREATE_ERROR: 'Booking URL for user already exists.',
    BOOKING_DETAILS_NOT_FOUND: 'Booking details not found.',
    REQUIRED_INPUT: 'Missing required input.',
    INVALID_BOOKING_URL: 'Invalid booking url',
    MISSING_AVAILABILITY_CONFIGURATION: 'Availability configuration not found.',
    FAILED_TO_SEND_EMAIL: 'Failed to send email: ',
    WEBHOOK_VERIFICATION: 'Error verifying webhook',
    MISSING_WEBHOOK_SECRET: 'webhook secret missing',
    MISSING_SVIX_HEADERS: 'svix headers missing',
    FAILED_TO_SEND_WELCOME_EMAIL: 'Failed to send welcome email',
    INVALID_DATE: 'Invalid date',
    INVALID_TIME: 'Invalid time',
    ORGANIZATION_CREATE_ERROR: 'organization creation error',
    TIME_RANGE_ERROR: 'End time cannot be before start time.',
    INVALID_DATE_FORMAT: 'Invalid date format.',
    OLD_BOOKING_DATE: 'Invalid booking date/time.',
    FAILED_FETCH_ORGANIZATIONS: 'Failed to fetch organizations.',
    NO_ORGANIZATION_MEMBERSHIP: 'No organization(s) information found.',
    INVALID_ORGANIZATION: 'Invalid organization.',
    BLOG_SUBSCRIBE_FAILED: 'Subscription failed.',
    SUBSCRIPTION_EXIST: 'User subscribed.',
    FAILED_EMAIL_NOTIFICATION: 'Failed to send.',
    FAILED_PAYMENT_CONFIG_UPDATE: 'Failed to update payment configuration',
    FAILED_PAYMENT_FETCH: 'Failed to fetch payment configuration.',
    FAILED_CREATE_CHECKOUT: 'Failed to checkout',
    INVALID_BOOKING: 'Invalid booking id',
    FAILED_TO_FETCH_ORGANIZATION_MEMBERS: 'Failed to fetch organization members',
    REQUIRED_QUERY: 'Required query missing. ',
    APPOINTMENT_BOOKING_FAILED: 'Failed to book appointment.',
    FAILED_TO_FETCH_BOOKING_SERVICE: 'Failed to fetch booking service.',
};

export const SuccessMessages = {
    BLOG_SUBSCRIBED: 'Thank you for subscribing! Stay tuned for our latest updates.',
};
