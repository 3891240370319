import moment, { HTML5_FMT } from 'moment';
import momentTimezone from 'moment-timezone';
import { DaysEnum } from './enums';
import { ErrorMessages } from '../message/error';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'hh:mm a';
export const TIME_FORMAT_24 = 'HH:mm';

export const getTimeSlots = (start: Date, end: Date, duration: number, timezone: string) => {
    const startTime = momentTimezone(start).tz(timezone);
    const endTime = momentTimezone(end).tz(timezone);

    if (endTime.isBefore(startTime)) {
        throw new Error(ErrorMessages.TIME_RANGE_ERROR);
    }

    const timeStops = [];
    const currentTime = startTime.clone(); // Clone startTime so we don't modify the original

    while (currentTime.isBefore(endTime)) {
        const _endTime = currentTime.clone();
        const timeRange = {
            startTime: currentTime.format(HTML5_FMT.TIME),
            endTime: _endTime.add(duration, 'minutes').format(HTML5_FMT.TIME),
        };

        // Add the time range only if it doesn't exceed the end time
        if (momentTimezone(_endTime, HTML5_FMT.TIME).isAfter(endTime)) {
            break;
        }

        timeStops.push(timeRange);
        currentTime.add(duration, 'minutes'); // Increment currentTime
    }
    return timeStops;
};

export const formatTime = (time: string, format: string = 'hh:mm a') => {
    return moment(time).format(format);
};

export const getTodayDate = (): Date => {
    return new Date();
};

export const addDuration = (time: string, duration: string) => {
    const startTime = moment(time);
    const formattedDuration = moment(duration).minutes();
    return startTime.add(formattedDuration, 'minutes').format('hh:mm a');
};

export const getTodaysDay = () => {
    return new Date().getDay() + 1;
};

export const DAYS_LIST = [
    DaysEnum.SUNDAY,
    DaysEnum.MONDAY,
    DaysEnum.TUESDAY,
    DaysEnum.WEDNESDAY,
    DaysEnum.THURSDAY,
    DaysEnum.FRIDAY,
    DaysEnum.SATURDAY,
];

export const getDefaultTimeZone = () => {
    return moment.tz.guess();
};

export const getUTCDateTime = (
    dateTime: Date | string,
    timeZone: string,
    isoString: boolean = true,
) => {
    if (isoString) {
        return momentTimezone(dateTime, HTML5_FMT.TIME).tz(timeZone).utc().toISOString();
    }
    return momentTimezone(dateTime, HTML5_FMT.TIME).tz(timeZone).utc().toDate();
};
export const getLocalDateTime = (dateTime: Date | string, timeZone: string) => {
    return momentTimezone(dateTime, HTML5_FMT.TIME).tz(timeZone).format(HTML5_FMT.DATETIME_LOCAL);
};

export function formatDateTime(
    date: Date | string,
    format: string = HTML5_FMT.DATETIME_LOCAL,
    timezone?: string,
) {
    const momentDate = momentTimezone(date);
    if (timezone) {
        return momentDate.tz(timezone).format(format);
    }
    return momentDate.format(format);
}
export function formatDateTimeWithOffset(dateTime: string, timeZone: string) {
    return momentTimezone(dateTime).tz(timeZone).format('YYYY-MM-DD HH:mm:ss Z');
}

export function constructDateTime(date: string, time: string, timezone?: string) {
    // Combine date and time
    const dateTime = `${date} ${time}`;
    const format = `${DATE_FORMAT} ${HTML5_FMT.TIME}`;
    // Parse the combined date and time with the given timezone format
    const momentDate = moment(dateTime, format);
    if (timezone) {
        const momentInZone = momentTimezone.tz(dateTime, format, timezone);
        return momentInZone.utc();
    }
    return momentDate.utc();
}

export const getTimeZones = () => {
    const timeZones = momentTimezone.tz.names();
    return timeZones;
};

export const getFormattedTimeZones = () => {
    const timeZones = getTimeZones();
    return timeZones.map((timeZone) => {
        const abbr = moment.tz(timeZone).format('z');
        const offset = moment.tz(timeZone).format('Z');
        return { label: `${timeZone} - ${abbr} (UTC${offset})`, value: timeZone };
    });
};

export const getDaysOptions = () => {
    return [
        {
            label: 'SUN',
            value: DaysEnum.SUNDAY,
        },
        {
            label: 'MON',
            value: DaysEnum.MONDAY,
        },
        {
            label: 'TUE',
            value: DaysEnum.TUESDAY,
        },
        {
            label: 'WED',
            value: DaysEnum.WEDNESDAY,
        },
        {
            label: 'THU',
            value: DaysEnum.THURSDAY,
        },
        {
            label: 'FRI',
            value: DaysEnum.FRIDAY,
        },
        {
            label: 'SAT',
            value: DaysEnum.SATURDAY,
        },
    ];
};

export const getTodayDateString = (timezone?: string) => {
    if (timezone) {
        return moment().tz(timezone).format(DATE_FORMAT);
    }
    return moment().format(DATE_FORMAT);
};

export const getTodayTimeString = (timezone?: string) => {
    if (timezone) {
        return moment().tz(timezone).format(HTML5_FMT.TIME);
    }
    return moment().format(HTML5_FMT.TIME);
};

export const getUtcDateString = (date: string, timeZone?: string) => {
    if (timeZone) {
        const utcDate = momentTimezone(date).tz(timeZone).utc().toDate();
        return utcDate;
    } else {
        const utcDate = momentTimezone(date).utc().toDate();
        return utcDate;
    }
};

export const getDateRange = (date: string, timeZone: string, day = 0) => {
    const momentDate = momentTimezone(date, DATE_FORMAT).tz(timeZone);
    const startDate = momentDate.utc().toDate(),
        endDate = momentDate.add(day, 'day').utc().toDate();
    return {
        startDate,
        endDate,
    };
};

export const getTimeString = (date: Date, timezone: string) => {
    const momentDate = momentTimezone(date, HTML5_FMT.DATETIME_LOCAL_MS).tz(timezone);
    return momentDate.format(HTML5_FMT.TIME);
};

export const isValidDateFormat = (date: string) => moment(date, DATE_FORMAT, true).isValid();

//? Note: Moment day returns 1-7 where 1 is Monday and 7 is Sunday, but we have 1 as Sunday & 7 Saturday.
// Hence incrementing by 1
export const getDayFromDate = (date: string | Date) => moment(date, DATE_FORMAT).day() + 1;

export const getDateFromTimeZone = (
    date: string,
    fromZone: string,
    toZone?: string,
    format = 'HH:mm',
) => {
    const momentDateTime = momentTimezone(date, 'HH:mm');
    if (toZone) {
        return momentTimezone(momentDateTime, fromZone).tz(toZone).format(format);
    }
    return momentDateTime;
};

export function extractStartEndTime(timeRange: string) {
    // Split the cleaned time range into start and end times
    const [startTime, endTime] = timeRange.split(' - ').map((time) => time.trim());

    return {
        startTime,
        endTime,
    };
}

export const isExpiredTime = (date: string, time: string, timeZone: string) => {
    const momentStart = moment(`${date} ${time}`, `${DATE_FORMAT} ${HTML5_FMT.TIME}`);
    const startDateTime = moment(momentStart, timeZone).utc();
    const currentDateTime = moment().utc();
    return startDateTime.isBefore(currentDateTime);
};

export const getDMYDateString = (isoDate: Date | string, timezone: string) => {
    return moment(isoDate).tz(timezone).format(DATE_FORMAT);
};

export const getHMTimeString = (isoDate: Date | string, timezone: string) => {
    return moment(isoDate).tz(timezone).format(HTML5_FMT.TIME);
};

export const getDateTimeDifference = (startTime: Date | string, endTime: Date | string) => {
    return moment(endTime).diff(moment(startTime), 'minutes');
};

export const getMDYDateString = (isoDate: Date | string, timezone: string) => {
    return moment(isoDate).tz(timezone).format('LL');
};

export const getHMTimeWithTzString = (isoDate: Date | string, timezone: string, zone = false) => {
    return moment(isoDate)
        .tz(timezone)
        .format(`hh:mm a ${zone ? 'z' : ''}`);
};

export function formatCustomDate(date: string | Date): string {
    const inputDate = moment(date);
    const now = moment();

    if (inputDate.isSame(now, 'week')) {
        return inputDate.calendar(null, {
            sameDay: '[Today at] h:mm A',
            nextDay: '[Tomorrow at] h:mm A',
            nextWeek: 'dddd [at] h:mm A',
            lastDay: '[Yesterday at] h:mm A',
            lastWeek: '[Last] dddd [at] h:mm A',
            sameElse: 'DD/MMM/YYYY [at] h:mm A',
        });
    } else {
        return inputDate.format('DD/MMM/YYYY [at] h:mm A');
    }
}
