import { ComponentPropsWithoutRef, ElementType } from 'react';
import { tv, VariantProps } from 'tailwind-variants';

export const dividerVariants = tv({
    base: 'h-px w-full',
    variants: {
        color: {
            primary: 'bg-primary-600',
            success: 'bg-success-600',
            danger: 'bg-danger-600',
            default: 'bg-neutral-300',
            'neutral-950': 'bg-neutral-950',
            'neutral-900': 'bg-neutral-900',
            'neutral-800': 'bg-neutral-800',
        },
        vertical: {
            true: 'h-full w-px ',
        },
        thick: {
            true: 'h-0.5',
        },
    },
    defaultVariants: {
        color: 'default',
        vertical: false,
        thick: false,
    },
    compoundVariants: [
        {
            vertical: true,
            thick: true,
            className: 'h-full w-0.5',
        },
    ],
});

export type DividerProps = ComponentPropsWithoutRef<'div' | 'hr' | 'span'> &
    VariantProps<typeof dividerVariants> & {
        as?: ElementType;
    };
