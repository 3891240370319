import { Flex } from '@/components/atoms/flex';
import { TimeRangeInput } from '@/components/molecules/fields/range-input';
import { SelectButtonInput, SelectInput } from '@/components/molecules/fields/select-input';
import { TextInput } from '@/components/molecules/fields/text-input';
import { Field } from 'formik';

export const OrganizationFormFields: React.FC<{
    fields: any[];
}> = ({ fields }) => {
    return (
        <Flex direction='column' gapY={4}>
            {fields.map(({ type, ...fieldData }: any) => {
                switch (type) {
                    case 'text':
                    case 'number':
                        return (
                            <Field
                                key={fieldData.label}
                                component={TextInput}
                                type={type}
                                {...fieldData}
                            />
                        );
                    case 'select':
                        return (
                            <Field key={fieldData.label} component={SelectInput} {...fieldData} />
                        );
                    case 'button-select':
                        return (
                            <Field
                                key={fieldData.label}
                                component={SelectButtonInput}
                                {...fieldData}
                            />
                        );
                    case 'time-range':
                        return <TimeRangeInput key={fieldData.label} {...fieldData} />;
                    default:
                        return <></>;
                }
            })}
        </Flex>
    );
};
