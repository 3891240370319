import { CustomFlowbiteTheme, createTheme } from 'flowbite-react';
import { Spinner as FSpinner, type SpinnerProps } from 'flowbite-react/components/Spinner';
import { forwardRef } from 'react';
import { Flex } from '../flex';

export const spinnerTheme: CustomFlowbiteTheme['spinner'] = createTheme({
    base: 'inline animate-spin text-gray-200',
    color: {
        primary: 'fill-primary-600 text-neutral-50',
        success: 'text-success-500',
        danger: 'fill-danger-600 text-neutral-50',
        accept: 'text-success-500',
        reject: 'text-danger-500',
    },
});

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>((props, ref) => {
    return <FSpinner ref={ref} {...props} theme={spinnerTheme} />;
});

Spinner.displayName = 'Spinner';

export const CenterSpinner = forwardRef<HTMLUListElement, SpinnerProps>((props, ref) => {
    return (
        <Flex
            ref={ref}
            as='div'
            id='spinner centered'
            justifyContent='center'
            alignItems='center'
            className='h-screen'
        >
            <FSpinner {...props} theme={spinnerTheme} />
        </Flex>
    );
});
