import { Tabs as FTabs, TabItem as FTabItem } from 'flowbite-react';
import type { TabItemProps, CustomFlowbiteTheme } from 'flowbite-react';
import { type TabsProps, tabsVariants } from './tabs.variants';

// Custom Tabs theme
export const tabsTheme: CustomFlowbiteTheme['tabs'] = {
    tablist: {
        tabitem: {
            base: 'flex items-center justify-start p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-neutral-400 disabled:dark:text-neutral-500 focus:ring-2 focus:ring-primary-200 focus:outline-none',
            variant: {
                default: {
                    active: {
                        on: 'bg-neutral-100 text-neutral-700 dark:bg-neutral-800 dark:text-primary-500',
                        off: 'text-neutral-700 hover:bg-neutral-50 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:hover:text-neutral-300',
                    },
                },
                underline: {
                    base: 'rounded-t-lg',
                    active: {
                        on: 'active rounded-t-lg border-b-2 border-primary-600 text-primary-600 dark:border-primary-500 dark:text-primary-500',
                        off: 'border-b-2 border-transparent text-neutral-500 hover:border-neutral-300 hover:text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-300',
                    },
                },
                pills: {
                    base: '',
                    active: {
                        on: 'rounded-lg bg-primary-700 text-white',
                        off: 'rounded-lg hover:bg-neutral-100 hover:text-neutral-900 dark:hover:bg-neutral-800 dark:hover:text-white',
                    },
                },
                fullWidth: {
                    base: 'ml-0 flex w-full rounded-none first:ml-0',
                    active: {
                        on: 'active rounded-none bg-neutral-100 p-4 text-neutral-900 dark:bg-neutral-700 dark:text-white',
                        off: 'rounded-none bg-white hover:bg-neutral-50 hover:text-neutral-700 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:hover:text-white',
                    },
                },
            },
        },
    },
    tabitemcontainer: {
        base: 'tab-item-container w-full',
    },
    tabpanel: 'py-3 px-2 focus:outline-none',
};

// Main Tabs component
export const TabsComponent: React.FC<TabsProps> = function ({
    children,
    vertical,
    className,
    ...rest
}) {
    return (
        <FTabs className={tabsVariants({ vertical, className })} {...rest} theme={tabsTheme}>
            {children}
        </FTabs>
    );
};

TabsComponent.displayName = 'Tabs';

export const TabItem: React.FC<TabItemProps> = function ({ children, ...rest }) {
    return <FTabItem {...rest}>{children}</FTabItem>;
};

TabItem.displayName = 'TabItem';

export const Tabs = Object.assign(TabsComponent, { Item: TabItem });
