import { FieldProps } from 'formik';
import { Select } from '@/components/atoms/select';
import { InputWrapper } from '../wrapper/input-wrapper';

import { Flex, FlexItem } from '@/components/atoms/flex';
import { OutlineButton } from '@/components/atoms/button';
import { getErrorMessage } from '@/libs/utils/helpers';
import { HelperText } from 'flowbite-react';

type CurrenctInputProps = FieldProps & {
    label: string;
    name: string;
    placeholder: string;
    required: boolean;
    value: string;
    options: { label: string; value: string; disabled?: boolean }[];
};

export const SelectInput: React.FC<CurrenctInputProps> = ({
    label,
    field,
    form,
    options = [],
    ...props
}) => {
    const error = getErrorMessage(field, form);
    const selectOptions = options.map((option, index) => (
        <option key={index + option.label} value={option.value} disabled={option.disabled}>
            {option.label}
        </option>
    ));
    const color = error ? 'failure' : 'neutral';
    return (
        <InputWrapper htmlFor={field.name} value={label} error={error}>
            <Select id={field.name} helperText={error} color={color} {...props} {...field}>
                {selectOptions}
            </Select>
        </InputWrapper>
    );
};

export const SelectButtonInput: React.FC<CurrenctInputProps> = ({
    label,
    field,
    form,
    options = [],
    // ...props
}) => {
    const error = getErrorMessage(field, form);
    const handleChange = (value: string | number) => {
        const selectedValues = Array.from(field.value) ?? [];
        const valueIndex = selectedValues.indexOf(value);
        if (valueIndex > -1) {
            selectedValues.splice(valueIndex, 1);
        } else {
            selectedValues.push(value);
        }
        form.setFieldValue(field.name, selectedValues);
    };

    const selectOptions = options.map((option) => (
        <FlexItem key={option.value}>
            <OutlineButton
                className='ring-0 focus:ring-0'
                outline={!field.value?.includes(option.value)}
                color={field.value?.includes(option.value) ? 'primary' : 'neutral'}
                onClick={() => handleChange(option.value)}
            >
                {option.label}
            </OutlineButton>
        </FlexItem>
    ));

    return (
        <InputWrapper htmlFor={field.name} value={label} error={error}>
            <Flex id={field.name} gapX={3} gapY={4} wrap>
                {selectOptions}
            </Flex>
            <HelperText color='failure' hidden={!error}>
                {error}
            </HelperText>
        </InputWrapper>
    );
};
